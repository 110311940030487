import React, { useState } from "react"
import { Box, Flex } from "@theme-ui/components"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import { Link } from "./link"
import { useCookies } from "react-cookie"

const LanguageSwitcher = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["nf_country"])
  const [show, setShow] = useState(false)

  function getCountry(countryCode) {
    const countries = {
      it: "it",
      en: "uk",
      "en-us": "us",
      "en-ca": "ca",
      fr: "fr",
      de: "de",
      es: "es",
      pt: "pt",
      pl: "pl",
      ru: "ru",
    }

    return countries[countryCode] ?? null
  }

  const handleClick = locale => {
    setCookie("nf_country", getCountry(locale), { path: "/" })
  }

  const languagesCountry = {
    it: "Italiano",
    en: "English",
    "en-us": "English (United States)",
    "en-ca": "English (Canada)",
    fr: "Français",
    de: "Deutsch",
    he: "Hebrew",
    es: "Español",
    pt: "Português",
    pl: "Polski",
    ru: "Русский",
  }
  return (
    <LanguageSwitcherContext.Consumer>
      {({ activeLocale, paths }) => {
        const activeLink = paths.find(x => x.locale === activeLocale)
        console.log(activeLink)
        return (
          <Box
            sx={{ position: "relative" }}
            onMouseEnter={() => setShow(!show)}
            onMouseLeave={() => setShow(!show)}
          >
            <Box
              sx={{
                marginLeft: activeLink.locale !== "he" ? 4 : 0,
                marginRight: activeLink.locale === "he" ? 4 : 0,
                color: "light",
                fontSize: 1,
                verticalAlign: "top",
                textTransform: "uppercase",
                textDecoration: "none",
                cursor: "default",
              }}
            >
              {activeLink.locale}
            </Box>
            <Box
              as={"ul"}
              sx={{
                flexDirection: "column",
                position: "absolute",
                margin: 0,
                padding: 3,
                backgroundColor: "light",
                listStyle: "none",
                boxShadow: "default",
                borderRadius: "sm",
                display: show ? "flex" : "none",
                "&:lang(he)": {
                  left: 0,
                  right: "auto",
                },
                right: 0,
              }}
            >
              {paths
                .sort((a, b) => a.locale.localeCompare(b.locale))
                .map((link, index) => (
                  <Box
                    as="li"
                    key={index}
                    onClick={() => handleClick(link.locale)}
                  >
                    <Link
                      href={link.value}
                      sx={{
                        whiteSpace: "nowrap",
                        padding: 2,
                        display: "block",
                        borderRadius: "xs",
                        color:
                          activeLocale === link.locale ? "primary" : "dark",
                        backgroundColor:
                          activeLocale === link.locale
                            ? "lightBackground"
                            : "light",
                      }}
                    >
                      {languagesCountry[link.locale.toLowerCase()] ||
                        link.locale}
                    </Link>
                  </Box>
                ))}
            </Box>
          </Box>
        )
      }}
    </LanguageSwitcherContext.Consumer>
  )
}

export default LanguageSwitcher
