const defaultLocale = "en"

const i18nPath = {
  it: {
    category: "categoria",
    search: "cerca",
    privacy: "privacy-policy",
    cookie: "cookie-policy",
  },
  en: {
    category: "category",
    search: "search",
    privacy: "privacy-policy",
    cookie: "cookie-policy",
  },
  he: {
    category: "category",
    search: "search",
    privacy: "privacy-policy",
    cookie: "cookie-policy",
  },
  es: {
    category: "category",
    search: "search",
    privacy: "privacy-policy",
    cookie: "cookie-policy",
  },
  de: {
    category: "category",
    search: "search",
    privacy: "privacy-policy",
    cookie: "cookie-policy",
  },
  pt: {
    category: "category",
    search: "search",
    privacy: "privacy-policy",
    cookie: "cookie-policy",
  },
  pl: {
    category: "category",
    search: "search",
    privacy: "privacy-policy",
    cookie: "cookie-policy",
  },
  ru: {
    category: "category",
    search: "search",
    privacy: "privacy-policy",
    cookie: "cookie-policy",
  },
  "en-us": {
    category: "category",
    search: "search",
    privacy: "privacy-policy",
    cookie: "cookie-policy",
  },
  "en-ca": {
    category: "category",
    search: "search",
    privacy: "privacy-policy",
    cookie: "cookie-policy",
  },
  fr: {
    category: "category",
    search: "search",
    privacy: "privacy-policy",
    cookie: "cookie-policy",
  },
}

export function getPagePath(page, locale) {
  const pageLocale = locale || page.locale
  let lang =
    pageLocale === defaultLocale ? "/" : `/${pageLocale.toLowerCase()}/`
  let path = page._allSlugLocales.find(x => x.locale === pageLocale)
    ? `${page._allSlugLocales.find(x => x.locale === pageLocale).value}`
    : null
  if (page.root || !page.treeParent) {
    return lang + `${path}/`
  }
  path = `${
    page.treeParent._allSlugLocales.find(x => x.locale === pageLocale)
      ? page.treeParent._allSlugLocales.find(x => x.locale === pageLocale).value
      : null
  }/${path}`
  if (page.treeParent.root) {
    return lang + `${path}/`
  }
  path = `${
    page.treeParent.treeParent._allSlugLocales.find(
      x => x.locale === pageLocale
    ).value
  }/${path}`
  return lang + `${path}/`
}

export function getHomePath(locale, value) {
  // if (typeof document !== "undefined") {
  //   const country = document.cookie.split("; ").find(row => row.startsWith('country='))?.split('=')[1]
  //   if (country === 'AU') {
  //     return '/ab-energy-australia/'
  //   }
  // }

  const localePrefix =
    locale === defaultLocale ? "" : `/${locale.toLowerCase()}`
  const isDefaultPath = !value || value === "home"
  return isDefaultPath ? `${localePrefix}/` : `${localePrefix}/${value}/`
}

export function getSearchPath(locale) {
  return locale === defaultLocale
    ? `/${i18nPath[locale.toLowerCase()].search}/`
    : `/${locale.toLowerCase()}/${i18nPath[locale.toLowerCase()].search}/`
}

export function getPrivacyPath(locale) {
  return locale === defaultLocale
    ? `/${i18nPath[locale.toLowerCase()].privacy}/`
    : `/${locale.toLowerCase()}/${i18nPath[locale.toLowerCase()].privacy}/`
}

export function getCookiePath(locale) {
  return locale === defaultLocale
    ? `/${i18nPath[locale.toLowerCase()].cookie}/`
    : `/${locale.toLowerCase()}/${i18nPath[locale.toLowerCase()].cookie}/`
}

export function getBlogPath(locale) {
  return locale === defaultLocale ? `/news/` : `/${locale.toLowerCase()}/news/`
}

export function getArticleCategoryPath(page, locale) {
  return locale === defaultLocale
    ? `/news/${i18nPath[locale.toLowerCase()].category}/${
        page._allSlugLocales.find(x => x.locale === locale)
          ? page._allSlugLocales.find(x => x.locale === locale).value
          : null
      }/`
    : `/${locale.toLowerCase()}/news/${
        i18nPath[locale.toLowerCase()].category
      }/${
        page._allSlugLocales.find(x => x.locale === locale)
          ? page._allSlugLocales.find(x => x.locale === locale).value
          : null
      }/`
}

export function getArticlePath(page, locale) {
  return locale === defaultLocale
    ? `/news/${
        page._allSlugLocales.find(x => x.locale === locale)
          ? page._allSlugLocales.find(x => x.locale === locale).value
          : null
      }/`
    : `/${locale.toLowerCase()}/news/${
        page._allSlugLocales.find(x => x.locale === locale)
          ? page._allSlugLocales.find(x => x.locale === locale).value
          : null
      }/`
}

export function getStoriesPath(locale) {
  return locale === defaultLocale
    ? `/stories/`
    : `/${locale.toLowerCase()}/stories/`
}

export function getStoryCategoryPath(page, locale) {
  return locale === defaultLocale
    ? `/stories/${i18nPath[locale.toLowerCase()].category}/${
        page._allSlugLocales.find(x => x.locale === locale)
          ? page._allSlugLocales.find(x => x.locale === locale).value
          : null
      }/`
    : `/${locale.toLowerCase()}/stories/${
        i18nPath[locale.toLowerCase()].category
      }/${
        page._allSlugLocales.find(x => x.locale === locale)
          ? page._allSlugLocales.find(x => x.locale === locale).value
          : null
      }/`
}

export function getStoryPath(page, locale) {
  return locale === defaultLocale
    ? `/stories/${
        page._allSlugLocales.find(x => x.locale === locale)
          ? page._allSlugLocales.find(x => x.locale === locale).value
          : null
      }/`
    : `/${locale.toLowerCase()}/stories/${
        page._allSlugLocales.find(x => x.locale === locale)
          ? page._allSlugLocales.find(x => x.locale === locale).value
          : null
      }/`
}
